import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./common.css";
import { Login } from "./Components/Login";
import GuestAuth from "./Components/contexts/auth/GuestAuth";
import RequireAuth from "./Components/contexts/auth/RequireAuth";
import { Dashboard } from "./Components/Dashboard";
import { Unauthorized } from "./Components/Unauthorized";
import ProfilePage from "./Components/MyProfile";
import { Navbar } from "./Components/Navbar";
import { MainLayout } from "./Components/MainLayout";
import { ViewData } from "./Components/ViewData";
import { DashboardProvider } from "./Components/contexts/ForDashboard";
import { ForgetPassword } from "./Components/ForgetPassword";
import { ResetPassword } from "./Components/ResetPassword";
import { Signup } from "./Components/Signup";
function App() {
  const loginUserInfo = localStorage.getItem("token");
  return (
    <div className="App">
      <div className="main-continer">
        <Routes>
          <Route
            path="login"
            element={
              <GuestAuth>
                <Login />
              </GuestAuth>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/signup"
            element={
              <GuestAuth>
                <Signup />
              </GuestAuth>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/forgot-password"
            element={
              <GuestAuth>
                <ForgetPassword />
              </GuestAuth>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/reset-password"
            element={
              <GuestAuth>
                <ResetPassword />
              </GuestAuth>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <DashboardProvider>
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </DashboardProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <DashboardProvider>
                  <MainLayout>
                    <ProfilePage />
                  </MainLayout>
                </DashboardProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/viewdata/:id"
            element={
              <RequireAuth>
                <DashboardProvider>
                  <MainLayout>
                    <ViewData />
                  </MainLayout>
                </DashboardProvider>
              </RequireAuth>
            }
          />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="" element={<Unauthorized />} />
        </Routes>
      </div>
    </div>
  );
}
export default App;
