import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import { toast } from "react-toastify";
import { useRouter } from "../../routes/hooks/router";

const AuthContext = createContext({
  auth: null,
  login: () => { },
  logout: () => { },
  signup:()=>{ },
});

export const AuthProvider = ({ children }) => {
  const router =  useRouter()
  const navigate = useNavigate();
  const [auth, setAuth] = useState({});
  const login = async (email, password) => {
    try {
      let obj = {
        username: email,
        password: password,
      };
      const response = await apiPostMethod(allApiUrl.USER_LOG_IN, obj);
      let userInfo = response?.user;
      localStorage?.setItem("token", response?.token);
      localStorage?.setItem("username",userInfo?.username)
      localStorage?.setItem("email",userInfo?.email)
      setAuth(userInfo);
      toast.success("Login successfully");
      setTimeout(() => {
        router.replace("/");
      }, 100);
    } catch (err) {
      toast.error("Please check userName and Password");
    }
  };
  const signup = async (username,email, password) => {
    try {
      let obj = {
        username:username,
        email: email,
        password: password,
      };
      const response = await apiPostMethod(allApiUrl.USER_SIGN_UP, obj);
      let userInfo = response?.user;
      // setAuth(userInfo);
      toast.success("User Register successfully");
      setTimeout(() => {
        router.replace("/login");
      }, 100);
    } catch (err) {
      toast.error("Please check userName and Password");
    }
  };
  const logout = ()=>{
    setAuth()
    localStorage.removeItem("token")
    localStorage.removeItem("username")
    localStorage.removeItem("email")
    router.replace("/login")
  }
  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        login,
        signup,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
