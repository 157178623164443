export const allApiUrl = {
  USER_LOG_IN: "login/",
  USER_SIGN_UP:"signup/",
  FIRST_SEARCH_DASHOARD: "search",
  IPRD_REFRECE_ID: "search_iprd",
  PATENT_NUMBER: "patents-autocomplete",
  INPUT_SEARCH: "unique_data",
  FORGOT_PASSWORD_LINK: "password-reset/",
  RESET_PASSWORD_LINK: "password-reset-confirm/",
  SEARCH_ATTRIBUTE_DATA: "search_by_attribute/",
  USER_LIMIT:"user_limit/"
};
