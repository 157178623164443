// import {
//   Box,
//   Container,
//   Typography,
//   IconButton,
//   styled,
//   Grid2,
// } from "@mui/material";
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
// const StyledFooter = styled(Box)(({ theme }) => ({
//   backgroundColor: "#0B3E68",
//   color: "#ffffff",
//   padding: "48px 0 24px 0",
//   position: "relative",
//   bottom: 0,
//   width: "100%",
// }));

// const FooterHeading = styled(Typography)({
//   fontWeight: 600,
//   marginBottom: "20px",
//   fontSize: "16px",
// });

// const FooterLink = styled(Typography)({
//   color: "#ffffff",
//   marginBottom: "10px",
//   cursor: "pointer",
//   fontSize: "12px",
//   transition: "color 0.3s ease",
//   "&:hover": {
//     color: "#90caf9",
//   },
// });

// const SocialIconButton = styled(IconButton)({
//   color: "#ffffff",
//   marginRight: "12px",
//   transition: "transform 0.3s ease, color 0.3s ease",
//   "&:hover": {
//     color: "#90caf9",
//     transform: "scale(1.1)",
//   },
// });

// const Footer = () => {
//   const companyLinks = [
//     {
//       link: "Patentability Search",
//       href: "https://patentskart.com/patentability-search/",
//     },
//     {
//       link: "State of the Art Search",
//       href: "https://patentskart.com/state-of-art/",
//     },
//     {
//       link: "Patent Invalidation Search",
//       href: "https://patentskart.com/patent-invalidation-search/",
//     },
//     {
//       link: "Freedom To Operate Search",
//       href: "https://patentskart.com/freedom-to-operate-search/",
//     },
//     { link: "Design Search", href: "https://patentskart.com/design-search/" },
//   ];

//   const helpLinks = [
//     {
//       link: "Technology Landscape",
//       href: "https://patentskart.com/technology-landscape/",
//     },
//     {
//       link: "Whitespace Analysis",
//       href: "https://patentskart.com/whitespace-analysis/",
//     },
//     {
//       link: "Patent Portfolio Analysis",
//       href: "https://patentskart.com/patent-portfolio-analysis/",
//     },
//     {
//       link: "Ip Watch or Patent Watch",
//       href: "https://patentskart.com/ip-watch-or-patent-watch/",
//     },
//   ];
//   const resourceLinks = [
//     {
//       link: "Ip Analytics Dynamic Dashboard",
//       href: "https://patentskart.com/ip-analytics-dynamic-dashboard/",
//     },
//     {
//       link: "Insidesep",
//       href: "https://patentskart.com/insidesep/",
//     },
//     {
//       link: "Drug Patent Linkage Database",
//       href: "https://patentskart.com/drug-patent-linkage-database/",
//     },
//   ];
//   const extraLinks = [
//     {
//       link: "Infringement Search",
//       href: "https://patentskart.com/infringement-search/",
//     },
//     {
//       link: "Eou Claim Chart Preparation",
//       href: "https://patentskart.com/eou-claim-chart-preparation/",
//     },
//     {
//       link: "Potential Licensee Search",
//       href: "https://patentskart.com/potential-licensee-search/",
//     },
//   ];
//   const extraService = [
//     {
//       link: "Office Action Response Draft",
//       href: "https://patentskart.com/office-action-response-draft/",
//     },
//     {
//       link: "Patent Drafting",
//       href: "https://patentskart.com/patent-drafting/",
//     },
//     {
//       link: "Trademark Support Services",
//       href: "https://patentskart.com/trademark-support-services/",
//     },
//   ];

//   return (
//     <StyledFooter component="footer" role="contentinfo">
//       <Container maxWidth="lg">
//         <Grid2 container spacing={4}>
//           <Grid2
//             item
//             size={{
//               xs: 12,
//               sm: 6,
//               md: 3,
//             }}
//           >
//             <FooterHeading variant="h6">Prior Art Searches</FooterHeading>
//             {companyLinks.map((link, index) => (
//               <FooterLink
//                 key={index}
//                 variant="body2"
//                 component="div"
//                 onClick={() => window.open(link?.href, "_blank")}
//               >
//                 {link?.link}
//               </FooterLink>
//             ))}
//           </Grid2>

//           <Grid2
//             item
//             size={{
//               xs: 12,
//               sm: 6,
//               md: 3,
//             }}
//           >
//             <FooterHeading variant="h6">Patent Analytics</FooterHeading>
//             {helpLinks.map((link, index) => (
//               <FooterLink
//                 key={index}
//                 variant="body2"
//                 component="div"
//                 onClick={() => window.open(link?.href, "_blank")}
//               >
//                 {link?.link}
//               </FooterLink>
//             ))}
//           </Grid2>

//           <Grid2
//             item
//             size={{
//               xs: 12,
//               sm: 6,
//               md: 3,
//             }}
//           >
//             <FooterHeading variant="h6">Specialized Services</FooterHeading>
//             {resourceLinks.map((link, index) => (
//               <FooterLink
//                 key={index}
//                 variant="body2"
//                 component="div"
//                 onClick={() => window.open(link?.href, "_blank")}
//               >
//                 {link?.link}
//               </FooterLink>
//             ))}
//           </Grid2>

//           <Grid2
//             item
//             size={{
//               xs: 12,
//               sm: 6,
//               md: 3,
//             }}
//           >
//             <FooterHeading variant="h6">Patent Licensing</FooterHeading>
//             {extraLinks.map((link, index) => (
//               <FooterLink key={index} variant="body2" component="div"  onClick={() => window.open(link?.href, "_blank")}>
//                 {link?.link}
//               </FooterLink>
//             ))}
//           </Grid2>
//           <Grid2
//             item
//             size={{
//               xs: 12,
//               sm: 6,
//               md: 3,
//             }}
//           >
//             <FooterHeading variant="h6">Patent Prosecution Support</FooterHeading>
//             {extraService.map((link, index) => (
//               <FooterLink key={index} variant="body2" component="div"  onClick={() => window.open(link?.href, "_blank")}>
//                 {link?.link}
//               </FooterLink>
//             ))}
//           </Grid2>
//         </Grid2>

//         <Box
//           sx={{
//             borderTop: "1px solid rgba(255, 255, 255, 0.1)",
//             marginTop: "32px",
//             paddingTop: "24px",
//             display: "flex",
//             flexDirection: { xs: "column", sm: "row" },
//             justifyContent: "space-between",
//             alignItems: { xs: "center", sm: "center" },
//             gap: "16px",
//           }}
//         >
//           <Typography
//             variant="body2"
//             sx={{ textAlign: { xs: "center", sm: "left" } }}
//           >
//             © {new Date().getFullYear()} Patentskart. All rights reserved.
//           </Typography>

//           <Box sx={{ display: "flex", gap: "8px" }}>
//             <SocialIconButton aria-label="Facebook" size="large">
//               <LinkedInIcon size={24} />
//             </SocialIconButton>
//             <SocialIconButton aria-label="Twitter" size="large">
//               <FacebookIcon size={24} />
//             </SocialIconButton>
//             <SocialIconButton aria-label="Instagram" size="large">
//               <TwitterIcon size={24} />
//             </SocialIconButton>
//             <SocialIconButton aria-label="LinkedIn" size="large">
//               <InstagramIcon size={24} />
//             </SocialIconButton>
//           </Box>
//         </Box>
//       </Container>
//     </StyledFooter>
//   );
// };

// export default Footer;
import {
  Box,
  Container,
  Typography,
  IconButton,
  styled,
  Grid2,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: "#0B3E68",
  color: "#ffffff",
  padding: "48px 0 24px 0",
  position: "relative",
  bottom: 0,
  width: "100%",
}));

const FooterHeading = styled(Typography)({
  fontWeight: 600,
  marginBottom: "20px",
  fontSize: "16px",
});

const FooterLink = styled(Typography)({
  color: "#ffffff",
  marginBottom: "10px",
  cursor: "pointer",
  fontSize: "12px",
  transition: "color 0.3s ease",
  "&:hover": {
    color: "#90caf9",
  },
});

const SocialIconButton = styled(IconButton)({
  color: "#ffffff",
  marginRight: "12px",
  transition: "transform 0.3s ease, color 0.3s ease",
  "&:hover": {
    color: "#90caf9",
    transform: "scale(1.1)",
  },
});

const Footer = () => {
  const companyLinks = [
    {
      link: "Patentability Search",
      href: "https://patentskart.com/patentability-search/",
    },
    {
      link: "State of the Art Search",
      href: "https://patentskart.com/state-of-art/",
    },
    {
      link: "Patent Invalidation Search",
      href: "https://patentskart.com/patent-invalidation-search/",
    },
    {
      link: "Freedom To Operate Search",
      href: "https://patentskart.com/freedom-to-operate-search/",
    },
    { link: "Design Search", href: "https://patentskart.com/design-search/" },
  ];

  const helpLinks = [
    {
      link: "Technology Landscape",
      href: "https://patentskart.com/technology-landscape/",
    },
    {
      link: "Whitespace Analysis",
      href: "https://patentskart.com/whitespace-analysis/",
    },
    {
      link: "Patent Portfolio Analysis",
      href: "https://patentskart.com/patent-portfolio-analysis/",
    },
    {
      link: "IP Watch or Patent Watch",
      href: "https://patentskart.com/ip-watch-or-patent-watch/",
    },
  ];

  const resourceLinks = [
    {
      link: "IP Analytics Dynamic Dashboard",
      href: "https://patentskart.com/ip-analytics-dynamic-dashboard/",
    },
    { link: "insidesSEP", href: "https://patentskart.com/insidesep/" },
    {
      link: "Drug Patent Linkage Database",
      href: "https://patentskart.com/drug-patent-linkage-database/",
    },
  ];

  const extraLinks = [
    {
      link: "Infringement Search",
      href: "https://patentskart.com/infringement-search/",
    },
    {
      link: "Eou Claim Chart Preparation",
      href: "https://patentskart.com/eou-claim-chart-preparation/",
    },
    {
      link: "Potential Licensee Search",
      href: "https://patentskart.com/potential-licensee-search/",
    },
  ];

  const extraService = [
    {
      link: "Office Action Response Draft",
      href: "https://patentskart.com/office-action-response-draft/",
    },
    {
      link: "Patent Drafting",
      href: "https://patentskart.com/patent-drafting/",
    },
    {
      link: "Trademark Support Services",
      href: "https://patentskart.com/trademark-support-services/",
    },
  ];

  return (
    <Box sx={{ padding: "24px" }}>
      <StyledFooter
        component="footer"
        role="contentinfo"
        style={{ borderRadius: "5px" }}
      >
        <Container maxWidth="lg">
          <Grid2
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Grid2 item xs={12} sm={6} md={2}>
              <FooterHeading variant="h6">Prior Art Searches</FooterHeading>
              {companyLinks.map((link, index) => (
                <FooterLink
                  key={index}
                  variant="body2"
                  component="div"
                  onClick={() => window.open(link.href, "_blank")}
                >
                  {link.link}
                </FooterLink>
              ))}
            </Grid2>

            <Grid2 item xs={12} sm={6} md={2}>
              <FooterHeading variant="h6">Patent Analytics</FooterHeading>
              {helpLinks.map((link, index) => (
                <FooterLink
                  key={index}
                  variant="body2"
                  component="div"
                  onClick={() => window.open(link.href, "_blank")}
                >
                  {link.link}
                </FooterLink>
              ))}
            </Grid2>

            <Grid2 item xs={12} sm={6} md={2}>
              <FooterHeading variant="h6">Specialized Services</FooterHeading>
              {resourceLinks.map((link, index) => (
                <FooterLink
                  key={index}
                  variant="body2"
                  component="div"
                  onClick={() => window.open(link.href, "_blank")}
                >
                  {link.link}
                </FooterLink>
              ))}
            </Grid2>

            <Grid2 item xs={12} sm={6} md={2}>
              <FooterHeading variant="h6">Patent Licensing</FooterHeading>
              {extraLinks.map((link, index) => (
                <FooterLink
                  key={index}
                  variant="body2"
                  component="div"
                  onClick={() => window.open(link.href, "_blank")}
                >
                  {link.link}
                </FooterLink>
              ))}
            </Grid2>

            <Grid2 item xs={12} sm={6} md={2}>
              <FooterHeading variant="h6">
                Patent Prosecution Support
              </FooterHeading>
              {extraService.map((link, index) => (
                <FooterLink
                  key={index}
                  variant="body2"
                  component="div"
                  onClick={() => window.open(link.href, "_blank")}
                >
                  {link.link}
                </FooterLink>
              ))}
            </Grid2>
          </Grid2>

          <Box
            sx={{
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
              marginTop: "32px",
              paddingTop: "24px",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ textAlign: { xs: "center", sm: "left" } }}
            >
              © {new Date().getFullYear()} Patentskart. All rights reserved.
            </Typography>

            <Box sx={{ display: "flex", gap: "8px" }}>
              <SocialIconButton
                aria-label="LinkedIn"
                size="large"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F27246674%2F",
                    "_blank"
                  )
                }
              >
                <LinkedInIcon />
              </SocialIconButton>
              <SocialIconButton
                aria-label="Facebook"
                size="large"
                onClick={() =>
                  window.open("https://www.facebook.com/PatentsKart/", "_blank")
                }
              >
                <FacebookIcon />
              </SocialIconButton>
              <SocialIconButton
                aria-label="Twitter"
                size="large"
                onClick={() =>
                  window.open(
                    "https://x.com/i/flow/login?redirect_after_login=%2Fpatentskart",
                    "_blank"
                  )
                }
              >
                <TwitterIcon />
              </SocialIconButton>
              <SocialIconButton
                aria-label="Instagram"
                size="large"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/patentskart/?igshid=1gr77dk0mt6w1",
                    "_blank"
                  )
                }
              >
                <InstagramIcon />
              </SocialIconButton>
            </Box>
          </Box>
        </Container>
      </StyledFooter>
    </Box>
  );
};

export default Footer;
