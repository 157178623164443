import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  CircularProgress,
  FormControl,
  FormLabel,
} from "@mui/material";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDashboardContext } from "./contexts/ForDashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { apiGetMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoute";
import TableHeadRow, { StyledTableCell, StyledTableRow } from "../shared/tableui/TableHead";
import { headCells, headCells2, headCells3 } from "../shared/constant";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import { toast } from "react-toastify";
const Loader = () => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      zIndex: 9999,
    }}
  >
    <CircularProgress />
  </Box>
);
const BlurredText = styled.a`
  display: inline-block;
  text-decoration: none;
  color: inherit;
  filter: ${(props) => (props.isBlurred ? "blur(4px)" : "none")};
  pointer-events: ${(props) => (props.isBlurred ? "auto" : "auto")};
  cursor: pointer;
  transition: filter 0.3s ease, color 0.3s ease;

  &:hover {
    color: ${(props) =>
    props.isBlurred ? "#3498db" : "inherit"}; /* Change color on hover */
  }
`;
const CustomMenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = Math.min(maxHeight, options.length * 35); // Adjust height based on options count

  return (
    <List
      height={height}
      itemCount={children.length}
      itemSize={35} // Height of each option
      width="100%"
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

export const ViewData = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchData, data, loading } = useDashboardContext();
  const queryParams = new URLSearchParams(location.search);
  const dpig = queryParams.get("dpig");
  const [filteredData, setFilteredData] = useState(null);
  const [ipData, setIpData] = useState([]);
  const [selectedFormCategory, setSelectedFormCategory] = useState(id);
  const currentResult = location.state;
  const [isBlurred, setIsBlurred] = useState(true);
  const [blurredText, setBlurredText] = useState({
    application: true,
    publication: true,
    patent: true,
    App_pub_pat_Number: true,
  });
  const [attempts, setAttempts] = useState(false);
  const [clickedOnce, setClickedOnce] = useState({
    application: false,
    publication: false,
    patent: false,
    App_pub_pat_Number: false,
  });
  useEffect(() => {
    const fetchFirstData = async () => {
      try {
        const response = await apiGetMethod(
          `${allApiUrl.FIRST_SEARCH_DASHOARD}${currentResult}`
        );
        setIpData(
          response?.result.map((item) => ({
            value: item.IPRD_REFERENCE,
            label: item.IPRD_REFERENCE,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };

    fetchFirstData();
  }, [currentResult, id]);

  useEffect(() => {
    fetchData(id);
  }, [id]);
  useEffect(() => {
    if (dpig && data) {
      const [displayNumber, index] = dpig.split(".").map(Number); // Split and convert to numbers

      const selectedData =
        data?.length > 0 &&
        data.find((item) => item.DIPG_DISPLAY_NUMBER === displayNumber);
      if (selectedData && selectedData.array) {
        const item = selectedData.array[index - 1]; // Adjusting for zero-based index
        setFilteredData(item);
      }
    }
  }, [dpig, data]);

  const handleCategoryChange = (e, dpigNumber) => {
    setSelectedFormCategory(e?.value);
    navigate(`/viewdata/${e?.value}?dpig=${dpigNumber}.1`, {
      state: currentResult,
    });
  };
  useEffect(() => {
    if (data && data.length > 0) {
      // Flatten the `array` fields across all `data` items
      const allItems = data.flatMap((item) => item.array);

      // Find the item that matches the current `id`
      const matchingCategory = allItems.find(
        (item) => item.IPRD_REFERENCE === id
      );


      if (matchingCategory) {
        // Trigger handleCategoryChange with the matching category
        handleCategoryChange(
          { value: matchingCategory.IPRD_REFERENCE },
          matchingCategory.DIPG_DISPLAY_NUMBER
        );
      }
    }
  }, [data, id]);

  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     handleCategoryChange(
  //       { target: { value: id } },
  //       data[0]?.DIPG_DISPLAY_NUMBER
  //     );
  //   }
  // }, [data, id]);

  // let getQuery = localStorage.getItem("setQuery");
  // const keyNames = getQuery
  //   ?.substring(1)
  //   ?.split("&")
  //   ?.map((pair) => pair?.split("=")?.[0]?.replace("[]", ""));
  // const displayText = keyNames?.join(" / ");
  let displayText;
  let getQuery = localStorage.getItem("setQuery"); // Retrieve the query string

  if (getQuery) {
    const keyMap = {
      STANDARD_SET: "SSO",
      TECH: "Technology",
    };

    const params = getQuery
      .substring(1) // Remove the leading "?" from the query string
      .split("&") // Split into key-value pairs
      .reduce((acc, pair) => {
        const [key, value] = pair.split("="); // Split key and value
        const formattedKey = key.replace("[]", ""); // Remove "[]" from the key
        const mappedKey = keyMap[formattedKey] || formattedKey; // Map the key if applicable
        const decodedValue = decodeURIComponent(value); // Decode the value

        // Group values under the same key
        if (!acc[mappedKey]) {
          acc[mappedKey] = [];
        }
        acc[mappedKey].push(decodedValue);
        return acc;
      }, {});

    // Format the result with specific separators
    const result = Object.entries(params)
      .map(([key, values]) => {
        const separator = key === "SSO" ? ", " : " / "; // Different separators for keys
        return `${key} = ${values.join(separator)}`;
      })
      .join(" / "); // Join SSO and technology outputs with a separator
    displayText = result;
  } else {
    console.log("No query string found in localStorage.");
  }
  const handleBlurClick = (e, type) => {
    e.preventDefault();

    if (!clickedOnce[type]) {
      // API call on first click
      apiGetMethod(allApiUrl?.USER_LIMIT)
        .then((res) => {
          if (res?.status === "False") {
            setAttempts(true);
            setIsBlurred(true); // Keep it blurred
            setBlurredText({
              application: true,
              publication: true,
              patent: true,
              App_pub_pat_Number: true,
            }); // Ensure all fields are blurred
            toast.error(res?.message); // Show error message
          } else {
            setIsBlurred(false); // Unblur the fields
            setBlurredText((prevState) => ({ ...prevState, [type]: false })); // Unblur the specific field
          }
        })
        .catch((err) => {
          console.error(err, "==========err");
        });

      setClickedOnce((prevState) => ({ ...prevState, [type]: true }));
    } else {
      // Redirection on second click
      if (type === "publication" && attempts === false) {
        window.open(
          `https://patents.google.com/patent/${filteredData.Publication_Number}/en?oq=${filteredData.Publication_Number}`,
          "_blank",
          "noopener,noreferrer"
        );
      } else if (type === "patent" && attempts === false) {
        window.open(
          `https://patents.google.com/patent/${filteredData.Patent_Number}/en?oq=${filteredData.Patent_Number}`,
          "_blank",
          "noopener,noreferrer"
        );
      }
    }
  };
  useEffect(() => {
    if (filteredData) {
      // Reset blurring and clicked states when new data arrives
      setBlurredText({
        application: true,
        publication: true,
        patent: true,
        App_pub_pat_Number: true,
      });
      setClickedOnce({
        application: false,
        publication: false,
        patent: false,
        App_pub_pat_Number: false,
      });
    }
  }, [filteredData]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10; // Show 10 items per page

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollHeight - scrollTop === clientHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    if (!ipData.some((item) => item.IPRD_REFERENCE === id)) {
      // Expand the list until the id is found
      setPage(
        Math.ceil(
          ipData.findIndex((item) => item.IPRD_REFERENCE === id) / itemsPerPage
        ) + 1
      );
    }
  }, [id, ipData]);
  return (
    <>
      {loading && <Loader />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          mt: 2,
          ml: 5,
        }}
        onClick={() => navigate("/")}
      >
        <ArrowBackIcon sx={{ mr: 1 }} />
        <Typography
          variant="h6"
          fontSize={{ xs: "12px", sm: "12px", md: "16px" }}
          fontWeight={600}
        >
          {id} ({displayText})
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          mt: 2,
          ml: 5,
          mb: 3,
        }}
      >
        <FormControl>
          <FormLabel fontWeight={600}>IPRD NUMBER</FormLabel>
          <Select
            value={{ value: selectedFormCategory, label: selectedFormCategory }}
            onChange={(e) => handleCategoryChange(e, data[0]?.DIPG_DISPLAY_NUMBER)}
            options={ipData}
            components={{ MenuList: CustomMenuList }}
            placeholder="Select an option"
            styles={{
              menu: (provided) => ({
                ...provided,
                maxHeight: 300,
              }),
            }}
          />
        </FormControl>
      </Box>
      <Card sx={{ maxWidth: "xl", margin: "auto" }}>
        <CardContent>
          {filteredData && (
            <>
              <Box sx={{ flexGrow: 1, p: 1.5 }}>
                <Typography fontWeight={600} fontSize="16px">
                  Standards/Specification Overview
                </Typography>
                <TableContainer sx={{ maxWidth: "xl", mt: 1 }}>
                  <Table>
                    <TableHeadRow headCells={headCells} />
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>
                          {filteredData.STANDARD || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Technology || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Sub_Technology || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.RECOMMENDATION || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.ILLUSTRATIVE_PART || "-"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ flexGrow: 1, p: 1.5 }}>
                <Typography fontWeight={600} fontSize="16px">
                  Patent Information
                </Typography>
                <TableContainer sx={{ maxWidth: "xl", mt: 1 }}>
                  <Table>
                    <TableHeadRow headCells={headCells2} />
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>
                          {filteredData.Application_Number ? (
                            <BlurredText
                              isBlurred={blurredText.application}
                              onClick={(e) => handleBlurClick(e, "application")}
                            >
                              {filteredData.Application_Number}
                            </BlurredText>
                          ) : (
                            "-"
                          )}
                        </StyledTableCell>

                        <StyledTableCell>
                          {filteredData.Publication_Number ? (
                            <BlurredText
                              isBlurred={blurredText.publication}
                              onClick={(e) => handleBlurClick(e, "publication")}
                            >
                              {filteredData.Publication_Number}
                            </BlurredText>
                          ) : (
                            "-"
                          )}
                        </StyledTableCell>

                        <StyledTableCell>
                          {filteredData.Patent_Number ? (
                            <BlurredText
                              isBlurred={blurredText.patent}
                              onClick={(e) => handleBlurClick(e, "patent")}
                            >
                              {filteredData.Patent_Number}
                            </BlurredText>
                          ) : (
                            "-"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Title || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Inventor || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.PATENT_OWNER || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Current_Assignee || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.COUNTRY_CODE || "-"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ flexGrow: 1, p: 1.5 }}>
                <Typography fontWeight={600} fontSize="16px">
                  Project/Patent Evaluations
                </Typography>
                <TableContainer sx={{ maxWidth: "xl", mt: 1 }}>
                  <Table>
                    <TableHeadRow headCells={headCells3} />
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>
                          {filteredData.IPRD_SIGNATURE_DATE || "-"}
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          {filteredData.Ess_To_Project ? "YES" : "No"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.Ess_To_Standard ? "YES" : "No"}
                        </StyledTableCell> */}
                        <StyledTableCell>
                          {filteredData.App_pub_pat_Number ? (
                            <BlurredText
                              isBlurred={blurredText.App_pub_pat_Number}
                              onClick={(e) =>
                                handleBlurClick(e, "App_pub_pat_Number")
                              }
                            >
                              {filteredData.App_pub_pat_Number}
                            </BlurredText>
                          ) : (
                            "-"
                          )}
                          {/* {filteredData.App_pub_pat_Number || "-"} */}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.DIPG_EXTERNAL_ID || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.PATT_ID || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.DIPG_PATF_ID || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {filteredData.DIPG_ID || "-"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
