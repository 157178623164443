import axios from "axios";
const requestTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const apiEndpoint = "https://python.insidesep.com/insidedb/";
const generateConfigData = (cancelToken = null, token = null) => {
  let header = {
    'Content-Type': 'application/json',
  };
  const clsData = localStorage?.getItem("token");

  const bearerToken = token ? token : clsData;

  if (bearerToken) {
    header.Authorization = `Token ${bearerToken}`;
  }

  let configData = { headers: header };
  if (cancelToken && Object.keys(cancelToken).length) {
    configData.cancelToken = cancelToken;
  }
  return configData;
};

export const apiPostMethod = (
  url,
  data,
  cancelToken = null,
  ct = null,
  token = ""
) => {
  return new Promise((resolve, reject) => {
    const configData = generateConfigData(cancelToken, token);
    axios
      .post(`${apiEndpoint}${url}`, data, configData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiGetMethod = (url, cancelToken = null, token = null) => {
  return new Promise((resolve, reject) => {
    const configData = generateConfigData(cancelToken, token);
    axios
      .get(`${apiEndpoint}${url}`, configData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response ? err.response.data : err.message);
      });
  });
};

// Example of a generate
// export const apiGetMethod = (url, cancelToken = null, token = null) => {
//   return new Promise((resolve, reject) => {
//     const configData = generateConfigData(cancelToken, token);
// console.log(`${apiEndpoint}${url}`,"+++++++++++")
//     axios
//       .get(`${apiEndpoint}${url}`, configData)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.response);
//       });
//   });
// };
// export const apiPostMethod = async (url, payload) => {
//   try {
//       const response = await fetch(`${apiEndpoint}/${url}`, {
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/json',
//               // Add any other headers if needed
//           },
//           body: JSON.stringify(payload),
//       });

//       if (!response.ok) {
//           // Throw an error if the response status is not OK
//           const errorText = await response.text();
//           throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
//       }

//       // Return the parsed JSON data
//       return await response.json();
//   } catch (error) {
//       console.error('Fetch error:', error);
//       throw error;
//   }
// };