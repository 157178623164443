import * as React from "react";
import {
  Drawer,
  List,
  ListItem,
  Divider,
  Typography,
  Grid2,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Tooltip,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDashboardContext } from "../contexts/ForDashboard";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { listSSO, newlistTechnologyDomain } from "../../shared/constant";
import useAuth from "../contexts/hooks/useAuth";
import LogoutIcon from '@mui/icons-material/Logout';
const Sidebar = () => {
  const { logout } = useAuth();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dpig = queryParams.get("dpig");
  const {
    setSelectedSSO,
    setSelectedTech,
    selectedSSO,
    selectedTech,
    fetchData,
    data,
    sidePanelOpen,
    setSidePanelOpen,
  } = useDashboardContext();
  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = React.useState(
    data?.[0]?.DIPG_DISPLAY_NUMBER
  );
  const [activeItem, setActiveItem] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [activeIndex, setActiveIndex] = React.useState("");

  React.useEffect(() => {
    if (dpig) {
      setActiveIndex(dpig);
    }
  }, [dpig]);

  const handleItemClick = (item) => {
    setActiveItem(item);
    setSidePanelOpen(!sidePanelOpen);
  };
  const filteredList = searchTerm
    ? listSSO.filter((item) =>
      item.label.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : listSSO;

  const newTechnologyList = searchTerm
    ? newlistTechnologyDomain.filter((item) =>
      item.label.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : newlistTechnologyDomain;
  const handleSelectAllSSO = (event) => {
    if (event.target.checked) {
      setSelectedSSO(filteredList.map((item) => item.label));
    } else {
      setSelectedSSO([]);
    }
  };

  const handleSelectAllTech = (event) => {
    if (event?.target?.checked) {
      setSelectedTech(newTechnologyList.map((item) => item.label));
    } else {
      setSelectedTech([]);
    }
  };

  const handleCheckboxChangeSSO = (item) => {
    setSelectedSSO((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  const handleCheckboxChangeTech = (item) => {
    setSelectedTech((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };
  // React.useEffect(() => {
  //   fetchData(id);
  // }, [id]);

  const handleToggle = (DIPG_DISPLAY_NUMBER) => {
    setExpandedIndex((prevIndex) =>
      prevIndex === DIPG_DISPLAY_NUMBER ? null : DIPG_DISPLAY_NUMBER
    );
  };
  React.useEffect(() => {
    setExpandedIndex(dpig?.split(".")[0]);
  }, [dpig]);
  const handleLogout = () => {
    logout();
  };
  return (
    <>
      {location?.pathname === "/" ? (
        <Drawer
          anchor="left"
          variant="permanent"
          sx={{
            width: activeItem !== null && sidePanelOpen ? 480 : 240,
            transition: "all 0.3s ease-in-out",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: activeItem !== null && sidePanelOpen ? 480 : 240,
              boxSizing: "border-box",
            },
          }}
        >
          <Grid2 container direction={"row"} height={"100%"}>
            <Grid2
              size={activeItem !== null && sidePanelOpen ? 6 : 12}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column", // Items stack vertically
              }}
            >
              <div>
                <img
                  src="/assets/icons/logo.png"
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "200px",
                    objectFit: "contain",
                    imageRendering: "crisp-edges",
                    cursor: "pointer"
                  }}
                  onClick={() => window.open("https://patentskart.com/insidesep/")}
                />
                <List sx={{ width: "100%" }}>
                  <ListItem
                    button
                    onClick={() => handleItemClick("SSO")}
                    sx={{
                      mb: 3,
                      backgroundColor:
                        activeItem === "SSO" ? "#0B3E68" : "transparent",
                      color: activeItem === "SSO" ? "white" : "inherit",
                      "&:hover": {
                        backgroundColor: "#0B3E68",
                        color: activeItem === "SSO" ? "white" : "inherit",
                      },
                    }}
                  >
                    <Typography
                      title="STANDARD SETTING ORGANIZATION"
                      variant="h6"
                      fontWeight={600}
                      fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                      sx={{
                        fontFamily: "Inter, sans-serif",
                        cursor: "pointer",
                      }}
                    >
                      SSO
                    </Typography>
                    <ArrowForwardIosIcon
                      sx={{
                        ml: "auto",
                        color: activeItem === "SSO" ? "white" : "inherit",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => handleItemClick("Technology")}
                    sx={{
                      backgroundColor:
                        activeItem === "Technology" ? "#0B3E68" : "transparent",
                      color: activeItem === "Technology" ? "white" : "inherit",
                      "&:hover": {
                        backgroundColor: "#0B3E68",
                        color:
                          activeItem === "Technology" ? "white" : "inherit",
                      },
                    }}
                  >
                    <Typography
                      title="Technology"
                      variant="h6"
                      fontWeight={600}
                      fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                      sx={{
                        fontFamily: "Inter, sans-serif",
                        cursor: "pointer",
                      }}
                    >
                      TECHNOLOGY
                    </Typography>
                    <ArrowForwardIosIcon
                      sx={{
                        ml: "auto",
                        color:
                          activeItem === "Technology" ? "white" : "inherit",
                      }}
                    />
                  </ListItem>
                </List>
              </div>
              <div style={{ flexGrow: 1 }}></div>{" "}
              <ListItem
                button
                onClick={handleLogout}
                sx={{
                  marginTop: "auto",
                  // color: "white",
                  "&:hover": {
                    backgroundColor: "#0B3E68", // Darker red on hover
                  },
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                  sx={{ fontFamily: "Inter, sans-serif", cursor: "pointer" }}
                >
                  LOGOUT
                </Typography>
                <LogoutIcon sx={{
                  ml: "auto",

                }} />
              </ListItem>
            </Grid2>

            {activeItem !== null && sidePanelOpen == true && (
              <Grid2
                size={6}
                pl={4}
                sx={{ overflowY: "auto", maxHeight: "100vh" }}
              >
                <TextField
                  label="Search Item"
                  variant="outlined"
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      height: "40px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      "& fieldset": {
                        borderColor: "#d1d1d1",
                      },
                      "&:hover fieldset": {
                        borderColor: "#a0a0a0",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3f51b5",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "normal",
                    },
                    "& .MuiInputBase-input": {
                      height: "100%",
                      padding: "0 12px",
                      width: "95%",
                    },
                    marginRight: "8px",
                  }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Divider orientation="horizontal" sx={{ mb: 3, mt: 3 }} />
                {activeItem === "SSO" && (
                  <FormGroup>
                    {filteredList?.length > 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              filteredList.length > 0 &&
                              selectedSSO.length === filteredList.length
                            }
                            onChange={handleSelectAllSSO}
                          />
                        }
                        label={
                          <Typography
                            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                            fontWeight={400}
                          >
                            Select All
                          </Typography>
                        }
                      />
                    )}
                    {filteredList.length > 0 ? (
                      filteredList.map((item, index) => (
                        <Tooltip
                          title={item?.Tooltip}
                          arrow
                          placement="right"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: "14px", // Adjust the font size here
                              },
                            },
                          }}
                        >
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={selectedSSO.includes(item?.label)}
                                onChange={() =>
                                  handleCheckboxChangeSSO(item?.label)
                                }
                              />
                            }
                            label={
                              <Typography
                                fontSize={{
                                  xs: "12px",
                                  sm: "12px",
                                  md: "12px",
                                }}
                                fontWeight={400}
                              >
                                {item?.label}
                              </Typography>
                            }
                          />
                        </Tooltip>
                      ))
                    ) : (
                      <div>No data found</div>
                    )}
                  </FormGroup>
                )}

                {activeItem === "Technology" && sidePanelOpen == true && (
                  <FormGroup>
                    {newTechnologyList.length > 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              newTechnologyList.length > 0 &&
                              selectedTech.length === newTechnologyList.length
                            }
                            onChange={handleSelectAllTech}
                          />
                        }
                        label={
                          <Typography
                            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                            fontWeight={400}
                          >
                            Select All
                          </Typography>
                        }
                      />
                    )}
                    {newTechnologyList.length > 0 ? (
                      newTechnologyList.map((item, index) => (
                        <Tooltip
                          title={item?.Tooltip}
                          arrow
                          placement="right"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: "14px", // Adjust the font size here
                              },
                            },
                          }}
                        >
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={selectedTech.includes(item?.label)}
                                onChange={() =>
                                  handleCheckboxChangeTech(item?.label)
                                }
                              />
                            }
                            label={
                              <Typography
                                fontSize={{
                                  xs: "12px",
                                  sm: "12px",
                                  md: "12px",
                                }}
                                fontWeight={400}
                              >
                                {item?.label}
                              </Typography>
                            }
                          />
                        </Tooltip>
                      ))
                    ) : (
                      <div>No data found</div>
                    )}
                  </FormGroup>
                )}
              </Grid2>
            )}
          </Grid2>
        </Drawer>
      ) : (
        <Drawer anchor="left" variant="permanent" sx={{ width: 290 }}>
          <Grid2 container direction="column" height="100%">
            <Grid2
              size={12}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                padding: 2,
              }}
            >
              <img
                src="/assets/icons/logo.png"
                alt="Logo"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "200px",
                  objectFit: "contain",
                  imageRendering: "crisp-edges",
                  cursor: "pointer"
                }}
                onClick={() => window.open("https://patentskart.com/insidesep/")}
              />
              <List sx={{ width: "240px" }}>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                  fontFamily="Poppins,sans-serif"
                >
                  DIPG
                </Typography>
                {data?.length > 0 && data?.map((item) => (
                  <React.Fragment key={item.DIPG_DISPLAY_NUMBER}>
                    <ListItem
                      button
                      onClick={() => handleToggle(item.DIPG_DISPLAY_NUMBER)}
                      sx={{
                        mb: 3,
                        backgroundColor:
                          activeIndex == item?.DIPG_DISPLAY_NUMBER
                            ? "#0B3E68"
                            : "transparent",
                        color:
                          activeIndex == item?.DIPG_DISPLAY_NUMBER
                            ? "white"
                            : "inherit",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                        fontFamily="Poppins,sans-serif"
                      >
                        {item?.DIPG_DISPLAY_NUMBER} ({item?.array?.length})
                      </Typography>
                      {expandedIndex === item.DIPG_DISPLAY_NUMBER ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ListItem>
                    {expandedIndex == item.DIPG_DISPLAY_NUMBER &&
                      item?.array?.map((subItem, idx) => (
                        <ListItem
                          key={subItem.id}
                          button
                          onClick={() =>
                            navigate(
                              `/viewdata/${id}?dpig=${item?.DIPG_DISPLAY_NUMBER
                              }.${idx + 1}`
                            )
                          }
                          sx={{
                            cursor: "pointer",
                            ml: 3, // Indentation for nested items
                            mb: 1,
                            backgroundColor:
                              activeIndex ==
                                `${item?.DIPG_DISPLAY_NUMBER}.${idx + 1}`
                                ? "#0B3E68"
                                : "transparent",
                            "&:hover": {
                              backgroundColor: "#0B3E68",
                            },
                            color:
                              activeIndex ==
                                `${item?.DIPG_DISPLAY_NUMBER}.${idx + 1}`
                                ? "white"
                                : "inherit",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                            fontFamily="Poppins,sans-serif"
                          >
                            {item?.DIPG_DISPLAY_NUMBER}.{idx + 1} -STANDARD{" "}
                            {subItem?.STANDARD}
                          </Typography>
                          <ArrowForwardIosIcon
                            sx={{
                              ml: 1,
                              color:
                                activeIndex ==
                                  `${item?.DIPG_DISPLAY_NUMBER}.${idx + 1}`
                                  ? "white"
                                  : "inherit",
                            }}
                          />
                        </ListItem>
                      ))}
                  </React.Fragment>
                ))}
              </List>
            </Grid2>
          </Grid2>
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
